































































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { BANK_ACCOUNT_BALANCE_CHECK } from '../store/bankAccount';
import { BankAccountBalanceCheckView, CheckBankAccountBalance } from '../types';
import { ApiResponse } from '@/components/types';
import { AuthorizationMixin } from '@/views/mixin';
import BankUseSelect from '../../bank/components/BankUseSelect.vue';
import ConservatorSelect from '../../conservator/components/ConservatorSelect.vue';
import { createCheckBankAccountBalance } from '@/modules/listOfAssets/model';
import AppTable from '@/components/AppTable.vue';

const BankAccount = namespace('listOfAssets/bankAccount');

@Component({
  components: {
    AppTable,
    BankUseSelect,
    ConservatorSelect
  }
})
export default class BankAccountBalanceCheck extends AuthorizationMixin {
  @BankAccount.Action(BANK_ACCOUNT_BALANCE_CHECK) balanceSearch!: (params: CheckBankAccountBalance) => Promise<ApiResponse<BankAccountBalanceCheckView[]>>;

  checkBankAccountBalance = createCheckBankAccountBalance(this.$auth.user().id);

  accounts: BankAccountBalanceCheckView[] = [];
  error: any = null;
  loading: boolean = false;
  loaded: boolean = false;

  balanceCheck() {
    this.error = null;

    return this.balanceSearch(this.checkBankAccountBalance);
  }

  reset(observer?: any) {
    this.checkBankAccountBalance = createCheckBankAccountBalance(this.$auth.user().id);
    this.accounts = [];

    setTimeout(() => {
      this.loading = false;
      this.loaded = false;
      this.error = null;
      observer && observer.reset();
    }, 0);
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.conservatorship'), value: 'conservatorship' },
      { align: 'left', text: this.$i18n.t('common.iban'), value: 'iban' },
      { align: 'left', text: this.$i18n.t('label.lastTransacted'), value: 'lastTransaction' },
      { align: 'right', text: this.$i18n.t('label.financeBalance'), value: 'balance' },
      { align: 'right', text: this.$i18n.t('label.bankSaldo'), value: 'saldo' },
      { align: 'right', text: this.$i18n.t('label.difference'), value: null, sortable: false }
    ];
  }

  get items() {
    return [
      ...(this.accounts).map((account) => ({
        ...account,
        transactedTrans: format(account.lastTransaction, 'dd.MM.yyyy'),
        saldoValutaTrans: format(account.saldoValuta, 'dd.MM.yyyy')
      }))
    ];
  }

  success(accounts: BankAccountBalanceCheckView[]) {
    this.accounts = accounts;
    this.loaded = true;
  }

  onError(error: any) {
    this.error = error;
    this.loaded = true;
  }
}
